.profile-tabs .ant-tabs-tab:hover {
  color: black !important;
}

.profile-tabs .ant-tabs-content-holder {
  overflow: auto;
  padding: 20px;
}

.profile-tabs.ant-tabs-left .ant-tabs-nav {
  margin-top: 24px;
}

.profile-tabs.ant-tabs-top .ant-tabs-nav {
  padding: 20px 20px 0;
  margin-bottom: 0;
}

.profile-input {
  height: 2.5em !important;
  border-radius: 8px !important;
  cursor: text !important;
}

.ant-input:disabled:hover {
  border-color: #d9d9d9 !important;
  cursor: not-allowed !important;
}

#change-details_businessName {
  cursor: text;
}

#change-details .ant-form-item-label > label {
  font-size: 14px !important;
  font-weight: 600;
}

#change-details_phoneNumber {
  cursor: text;
}

/*profile modal style*/
.editProfileModal .ant-modal-content {
  margin: auto;
  border-radius: 12px;
  /* width: 90%; */
}

.dispatch-rules-description .ant-descriptions-view {
  max-width: 500px;
}
