/*login styles*/

.account-form .ant-form-item-label > label {
  font-size: 16px !important;
  font-weight: 600;
}

.account-form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
