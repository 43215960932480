.auth0-lock-blur-support {
  display: none;
}

.auth0-lock-name {
  font-weight: 500;
}

.auth0-lock-cred-pane-internal-wrapper {
  height: fit-content !important;
}

.auth0-lock-submit {
  margin-top: 10px !important;
  border-radius: 5px !important;
  height: 60px !important;
  vertical-align: middle !important;
  display: flex !important;
  height: 45px !important;
  align-items: center;
  justify-content: center;
}

.auth0-lock-terms {
  background: none !important;
}

.auth0-loading-container {
  left: 12% !important;
}

.auth0-loading {
  top: -15px !important;
  width: 20px !important;
  height: 20px !important;
}

.auth0-lock-input-wrap {
  background: none !important;
  border-radius: 8px !important;
}

.auth0-lock-social-big-button {
  border-radius: 8px !important;
}
