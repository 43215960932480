/* Todays rides tab styles*/
.order-tabs .ant-tabs-nav .ant-tabs-ink-bar {
  background: none !important;
}

.ant-tabs-ink-bar {
  background: #08323c !important;
}

.request-ride-button:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

/*cancel ride modal style*/
.cancelRideModal .ant-modal-content {
  margin: auto;
  border-radius: 12px;
  width: 75%;
}

.order-card {
  box-shadow: 0px 0px 0px 1px rgba(23, 70, 141, 0.1), 0px 2px 12px 1px rgba(15, 15, 15, 0.1);
}

.order-table thead[class*="ant-table-thead"] th {
  background-color: #eaecf0 !important;
}

.order-table .ant-table-row td {
  padding: 14px !important;
}

.order-table:not(.table-mobile) table th {
  padding: 10px !important;
}

.order-table .table-mobile table th {
  padding: 0px !important;
}

.order-table .ant-table-row td:first-child {
  border-left: 1px solid #eaecf0;
}

.order-table .ant-table-row td:last-child {
  border-right: 1px solid #eaecf0;
}

.order-table table {
  border-radius: 8px !important;
  height: 1px;
}

#draft-order-table .order-table .ant-table-expanded-row td {
  padding: 0;
  border: 0;
}

.order-table table td {
  vertical-align: top;
}

.order-table .ant-table-row-expand-icon-cell {
  vertical-align: middle !important;
}

.order-table thead .ant-table-cell {
  font-weight: 700;
  letter-spacing: 0.05em;
  color: black;
  height: 100%;
}

.ant-table-cell {
  padding: 10px !important;
}

.order-table th.ant-table-cell::before {
  display: none;
}

.ant-pagination-item-link span {
  vertical-align: middle !important;
  margin-bottom: 5px !important;
}

.order-table .ant-table-cell-row-hover .edit-button {
  visibility: visible;
}

.order-table .table-row-error {
  background-color: #fff0f0;

  td:first-child:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: #ff4d4f;
  }
}

.order-table .ant-table-row-selected td {
  background: #fff !important;
  &:first-child:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
  }
}

.ant-select:not(.ant-select-disabled) {
  .ant-select-selection-placeholder {
    color: rgb(55, 65, 81);
  }
}

.ant-picker:not(.ant-picker-disabled) {
  .ant-picker-input input::placeholder {
    color: rgb(55, 65, 81);
  }
}

#draft-order-table .ant-table-row.ant-table-row-level-1 {
  background-color: rgb(249, 250, 252);

  td {
    border-bottom: none;
  }

  .ant-table-selection-column > * {
    display: none;
  }

  .ant-table-cell-with-append > * {
    display: none;
  }
}

.kosmo-date-range-picker-small div.ant-picker-input > input {
  text-align: center;
  width: 50px;
}

@keyframes disappearBounce {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0.1);
  }
}

.infinite-scale-opacity {
  animation: disappearBounce 3s alternate infinite;
}

@keyframes disappearRight {
  0% {
    opacity: 0.5;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.translate-right-opacity {
  animation: disappearRight 2s forwards;
}
