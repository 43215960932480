#payment-element {
  margin-bottom: 24px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-form {
  width: 100%;

  align-self: center;
}

.payment-button {
  color: white;
  font-weight: 600;
  border-radius: 8px;
  padding: 0.8em;
  width: 100%;
  height: 3.5em;
}
