.settings-filter .ant-select-selector {
  border-radius: 6px !important;
}

.settings-filter .ant-input {
  font-size: 0.875rem;
}

#team-people-form .ant-form-item {
  margin-bottom: 12px;
}
