/* Load in Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "tailwind-preflight.css";

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
