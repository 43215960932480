/*Primary button styles*/
.primary {
  color: white !important;
  border: none !important;
  font-weight: 600 !important;
}

.form {
  height: 3.5rem;
  width: 100%;
  color: white;
}

.form:hover {
  background-color: #5500ff;
  color: white;
  border-color: none;
}

/* .secondary {
  background-color: white;
  font-weight: 400;
} */

.success {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: white !important;
  background-color: rgb(22 163 74);
}

.danger {
  font-size: 14px;
  color: white;
  background-color: rgb(220 38 38);
}

.primary:hover {
  background-color: #5500ff !important;
  border: none !important;
  outline: none !important;
}

.danger:hover {
  color: white;
  border: none;
  background-color: rgb(239 68 68);
  outline: none;
}

.danger:focus {
  color: white;
  border: none;
  background-color: rgb(239 68 68);
  outline: none;
}

.success:hover {
  color: white !important;
  border: none !important;
  background-color: rgb(34 197 94);
  outline: none !important;
}

.success:focus {
  color: white !important;
  border: none !important;
  background-color: rgb(34 197 94);
  outline: none !important;
}

.secondary:active {
  color: white !important;
  transition: background-color 500ms ease-in, color 500ms ease-in !important;
}

/* Disabled Button */
/* .form[disabled],
.primary[disabled],
.primary[disabled]:hover,
.primary[disabled]:focus,
.primary[disabled]:active {
  color: white !important;
  background-color: #a0bbff !important;
  border: none !important;
} */

/* .kosmo-btn-base.ant-btn-primary,
.kosmo-btn-base.ant-btn-secondary,
.kosmo-btn-base.primary,
.kosmo-btn-base.secondary {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  user-select: none;
  touch-action: manipulation;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 8px;
  border-color: #d9d9d9;
  border-radius: 0.4em !important;
} */

.custom-dropdown button {
  border-radius: 0.4em !important;
}

/* button:disabled {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
} */
