/*store wallet styles*/

.card-highlighted {
  outline: 1px solid rgba(65, 0, 255, 0.4) !important;
  box-shadow: rgba(65, 0, 255, 0.2) 0px 2px 20px 0px;
}

/* Store Wallet tab styles*/
.ant-tabs-tab .ant-tabs-tab-btn > span.wallet-tab {
  color: black !important;
}

/*Onboarding styles*/
.onboarding-input {
  height: 3.5em !important;
  border-radius: 8px !important;
  cursor: text;
}
.onboarding-select {
  font-size: 14px;
}

.onboarding-select > .ant-select-selector {
  border-radius: 8px !important;
  height: 50px !important;
}

.onboarding-select input {
  height: 50px !important;
}

.onboarding-select .ant-select-selection-placeholder {
  line-height: 48px !important;
}
.onboarding-select
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 48px !important;
}

.onboarding-select .ant-select-selection-item > div {
  line-height: 39px;
}

/*schedule tabs*/
.schedule-tabs > .ant-tabs-nav {
  position: fixed !important;
  background-color: white !important;
  padding-top: 10px !important;
  width: 100%;
}

.interval-tabs > .ant-tabs-nav {
  position: fixed !important;
  background-color: white !important;
  padding-top: 10px !important;
  width: 100%;
}

/* Disabled delivery type card */

.disabled-card {
  background: repeating-linear-gradient(45deg, white, rgb(203 213 225) 4px);
}

.interval-tabs > .ant-tabs-nav-wrap {
  position: fixed !important;
}

.interval-tabs > .ant-tabs-content-holder > .ant-tabs-content {
  margin-top: 1em;
}

.interval-tabs > .ant-tabs-content-holder {
  margin-top: 3.7em !important;
}

.schedule-tabs > .ant-tabs-content-holder {
  margin-top: 3.7em !important;
}

@media only screen and (max-width: 1022px) {
  .interval-tabs > .ant-tabs-nav {
    position: fixed !important;
    background-color: white !important;
    padding-top: 10px !important;
    width: 355px;
  }
}
