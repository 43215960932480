/* Load in Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "tailwind-preflight.css";

body {
  overflow-y: scroll !important;
}

html,
body {
  scroll-behavior: smooth !important;
}

.ant-table-row-expand-icon-cell {
  display: flex;
  height: 100%;
}

.ant-checkbox-inner {
  border-radius: 2px;
}

.-title-content {
  width: 100%;
  display: flex;
}

/* Typography */
.title-0-mb {
  margin-bottom: 0 !important;
}

/*All input styles*/
input {
  color: rgba(35, 35, 35, 1);
}

.black-selector .ant-select-selector {
  background: black !important;
  > * {
    color: white !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  border-radius: 8px 0 0 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 0 8px 8px 0 !important;
}

.country-select .ant-select-selector {
  background-color: #f6f6f6 !important;
}

.ant-select-item-option-state {
  display: none !important;
}

.ant-input:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input-wrapper {
  input {
    border-radius: 0 8px 8px 0 !important;
    /* border: 1px solid #d9d9d9 !important; */
  }
}

.time-at-stop {
  .ant-input-group > .ant-input {
    height: 28px;
    border-radius: 8px 0px 0px 8px !important;
  }
}

.withAddOnInput {
  .ant-input-group > .ant-input {
    border-radius: 8px 0px 0px 8px !important;
  }
}

input[type] {
  border-radius: 8px !important;
}

/*Desktop nav styles*/
.desktop-nav > li.ant-menu-item-selected {
  background-color: white !important;
}

.menu-item-no-underline.ant-menu-item.ant-menu-item-active::after {
  content: none !important;
}

.menu-item-no-underline {
  /* cursor: default !important; */
}

.menu-item-no-underline::after {
  content: none !important;
}

.menu-item-no-underline.ant-menu-item.ant-menu-item-active:hover {
  color: black !important;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 12px !important;
}

/*Mobile nav styles*/
.ant-menu-item a {
  color: black !important;
}

/*Float Input Styles*/
.float-label {
  position: relative;
}

.label {
  z-index: 49;
  font-weight: 600;
  position: absolute;
  pointer-events: none;
  left: 39px;
  top: 14px;
  transition: 0.2s ease all;
}

input::placeholder {
  font-size: 13px !important;
}

.float-label > ::placeholder {
  font-size: 13px !important;
}

.as-placeholder {
  color: gray;
  padding-right: 0.4em;
  margin-top: auto;
  font-size: 13px !important;
}

.as-label {
  z-index: 49;
  top: -8px;
  left: 25px;
  font-size: 10px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: gray;
}

.middle.label {
  z-index: 49;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  left: 35px;
  top: 7px;
  transition: 0.2s ease all;
}

.small.as-placeholder {
  top: 6px;
}

.middle.as-placeholder {
  color: gray;
  padding-right: 0.4em;
  margin-top: auto;
  font-size: 12px !important;
}

.middle.as-label {
  z-index: 49;
  top: -10px;
  left: 20px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: gray;
}

.as-label-textArea {
  z-index: 49;
  top: -9px;
  left: 20px;
  font-size: 13px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: gray;
}

.middle.as-label-textArea {
  z-index: 49;
  top: -10px;
  left: 20px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: gray;
}

.as-phone-placeholder {
  color: gray;
  left: 8em;
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-tc::after {
}

/* Select styles */

.phone-select .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: white !important;
  box-shadow: none !important;
}

.ant-select-selection-item {
  display: flex !important;
}

.ant-select-selection-search-input {
  box-shadow: none !important;
  outline: none !important;
}

.status-filter .ant-select-focused .ant-select-selector,
.ant-select-open .ant-select-selector .ant-select-selection-search-input {
  box-shadow: none !important;
}

.phone-select .ant-select-selector:hover {
  border-color: white !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.phone-select .ant-select-selection-search .ant-select-selection-search-input {
  text-indent: unset !important;
}

.status-filter .ant-select-selector:hover {
  box-shadow: none !important;
}

/* Form Progres counter */
.orders-progress .ant-progress-steps-outer {
  width: 200px !important;
}

.drivers-progress .ant-progress-steps-outer {
  width: 300px;
  flex-wrap: wrap;
}

.zero-gap .ant-progress .ant-progress-steps-item {
  margin-inline-end: 0px;
}

.small-gap .ant-progress .ant-progress-steps-item {
  margin-inline-end: 1px;
}

.ant-progress-steps-item {
  flex: 1 1 auto;
}

.kosmo-progress-8 .ant-progress-steps-item {
  width: 10% !important;
}

.kosmo-progress-7 .ant-progress-steps-item {
  width: 12% !important;
}

.kosmo-progress-6 .ant-progress-steps-item {
  width: 14% !important;
}

.kosmo-progress-5 .ant-progress-steps-item {
  width: 17% !important;
}

.kosmo-progress-4 .ant-progress-steps-item {
  width: 20% !important;
}

.kosmo-progress-3 .ant-progress-steps-item {
  width: 28% !important;
}

.kosmo-progress-2 .ant-progress-steps-item {
  width: 45% !important;
}

@media only screen and (max-width: 1022px) {
  .ant-progress-steps {
    width: 100% !important;
    /* position: absolute !important; */
    left: 0% !important;
    right: 0% !important;
    top: 50px !important;
    transform: translate(-0%, -0%) !important;
    margin-bottom: 0 !important;
    z-index: 1 !important;
  }
}

.ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.ant-layout-header {
  z-index: 4 !important;
}

.date-picker-filter {
  cursor: default;
}

.date-picker-time-preset {
  background: #f0e6ff;
  box-sizing: border-box;
  border-color: #a97aff;
  cursor: pointer;
}

.date-picker-filter .ant-input-disabled {
  text-align: center;
  color: black;
  cursor: default;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.main-layout {
  height: 100vh;
  max-height: -webkit-fill-available;
}

.ant-select-item-option-content div img {
  height: 1.25rem;
}

@media screen and (max-width: 600px) {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

.menu-item-border-effect {
  bottom: 0;
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.2s ease;
}

.items-list {
  position: relative;
  display: flex;
}
/* 
.gm-style .gm-style-iw-c {
  padding: 8px;
}

.gm-style .gm-style-iw-c .gm-style-iw-d {
  padding-right: 8px;
  padding-bottom: 8px;
  overflow: auto !important;
}

.gm-style .gm-style-iw-tc {
  transform: translate(-50%, -43px) rotate(180deg);
} */

.ant-popover-inner-content .ant-popover-buttons {
  display: none;
}

.ant-popover-inner-content .anticon-exclamation-circle {
  display: none;
}

.ant-popover-inner-content .ant-popover-message-title {
  padding-left: 0;
}

.gm-style-cc {
  /* display: none; */
}

.draft-order-rule-selector:not(.ant-select-disabled) {
  .ant-select-selector {
    color: black;
  }

  .ant-select-selection-item {
    font-size: 0.875rem;
  }

  .ant-select-selection-placeholder {
    font-size: 0.875rem;
  }
}

.ant-input-affix-wrapper-status-error:hover {
  border-color: #ff4d4f !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):not(.custom-error)
  .ant-select-selector {
  border: none !important;
}

#create-org-form .ant-select-selection-placeholder {
  color: rgb(156 163 175);
  font-size: 14px;
  font-weight: 400;
}

.schedule-tag-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #d8e6fd;
  border-color: #d8e6fd;
  border-radius: 9999px;
  padding: 0 12px;

  .ant-select-selection-item {
    padding-right: 14px;
  }
  .ant-select-selection-placeholder {
    color: black;
  }
}

.notifications-tab .ant-tabs-content-holder {
  padding: 0 !important;
}

.tip-radio::before {
  display: none !important;
}

#draft-error-button-popover {
  width: 85px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-form-item-label {
  padding: 0 !important;
  font-size: 10px !important;
}

/* This part could help have a streched menu with delivery options */
.request-rider-tabs .ant-tabs-tab .ant-tabs-tab-active {
  width: 100%;
  display: flex;
  /* flex-grow: 1; */
}

.request-rider-tabs .ant-tabs-nav-list {
  /* width: 100%; */
  display: flex;
}

.request-rider-tabs .ant-tabs-nav-wrap {
  /* width: 100%; */
  display: flex;
}

.request-rider-tabs .ant-tabs-nav-list {
  /* display: flex; */
  /* width: 90%; */
}

.request-rider-tabs .ant-tabs-tab:not(.tab-mobile) {
  min-width: 20%;
  /* min-width: 80px; */
  /* display: flex; */
}
.request-rider-tabs .ant-tabs-tab {
  justify-content: center;
}

.ant-tabs-nav-operations .ant-tabs-nav-operations-hidden {
  /* position: inherit; */
  /* visibility: visible; */
}

.ant-tree-node-content-wrapper .ant-tree-node-content-wrapper-normal {
  width: 100%;
}

.ant-tree-treenode:hover::before {
  background-color: transparent !important;
}

#smart-assignment-menu-blast-popup {
  padding-top: 10px;
  padding-bottom: 10px;

  li.ant-menu-item.ant-menu-item-only-child {
    display: flex !important;
    height: fit-content !important;
    line-height: 1.5em;
  }
}

#smart-assignment-menu {
  .ant-menu-submenu-title:hover {
    color: black;
    opacity: 0.85;
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
    color: black;
  }
  .ant-menu-submenu-title {
    line-height: 0;
    color: black;
    opacity: 0.85;
    height: fit-content;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .ant-menu-item-group-title {
    font-size: 12px;
  }
}

.ant-table-row-level-1 {
  background-color: #f8fafc;
}

#dropdown-item-button {
  width: 100%;
  justify-content: space-between;
  justify-items: start;
  padding: 0;
}

#blast-assignment-form {
  .ant-select-selection-placeholder {
    color: #6b7280 !important;
    font-size: 14px !important;
    font-style: italic;
    opacity: 0.9;
  }
}

.ant-ribbon-wrapper {
  height: 100%;
}

.upload-pod-form {
  .ant-upload-picture-card-wrapper {
    width: fit-content !important;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
    padding: 0;
  }

  .ant-upload-list-picture-card-container {
    height: 45px !important;
    width: 45px !important;
  }

  .ant-upload-select.ant-upload-select-picture-card {
    height: 45px !important;
    width: 45px !important;
  }

  .ant-upload-select {
    height: 45px !important;
    width: 45px !important;
  }

  .ant-upload-list-picture-card {
    opacity: 1;
  }
}

.info-window {
  background-color: #fff;
  padding: 5px;
}

.info-header {
  font-size: 16px;
  text-align: center;
}

.info-content {
  margin-top: 5px;
}

.gm-ui-hover-effect {
  display: block !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
}

#request-rider {
  .gm-style-iw.gm-style-iw-c {
    padding: 8px !important;
    padding: 8px !important;
    background-color: black !important;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style .gm-style-iw-tc::after {
    display: none;
  }
}

.virtuallist {
  min-height: calc(100vh - 300px) !important;
}

.create-order-modal {
  .ant-modal-header {
    background: #f8fafc;
  }
  .ant-modal-content {
    background: #f8fafc;
  }
}

.kosmo-ownfleet-cell ~ .ant-badge-count {
  background: white;
  border: 1px solid red;
  color: red;
  box-sizing: content-box;
}

.gm-style-iw-ch {
  padding: 0;
}
/* Close icon infowindow */
.gm-ui-hover-effect {
  /* Hidding default infowindow close button to manage padding */
  display: none !important;
  width: 8px !important;
  height: 8px !important;
  margin: 2px !important;
  margin-right: 16px !important;
  span {
    margin: 0 !important;
    width: 12px !important;
    height: 12px !important;
  }
}

.gm-style-iw-tc::after {
  display: none;
}

.ant-tree-title {
  width: 97% !important;
  display: flex;
}

.details-pod-preview {
  .ant-image-preview-img {
    max-width: 500px !important;
  }
}

.ant-upload-drag-driver {
  .ant-upload-drag {
    width: 100% !important;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  padding: 0;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 0; /* Firefox */
}

.gm-style iframe + div {
  border: none !important;
}

#onboarding-page {
  .ant-progress-bg {
    height: 2px !important;
  }
}

.ant-dropdown-trigger.map-dropdown-actions {
  border-radius: 8px !important;
}

.shine {
  text-decoration: none;
  display: inline-block;
  position: relative;
  -webkit-mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 1) 50%,
    #000 0%,
    rgba(0, 0, 0, 0.6) 60%
  );
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 1) 50%, #000 0%, rgba(0, 0, 0, 0.6) 60%);
  -webkit-mask-size: 200%;
  mask-size: 200%;
  animation: shine-frame 2s linear infinite;
}

@keyframes shine-frame {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}

.glowing-element {
  animation: glow 1.2s infinite alternate;
}

.glowing-button {
  animation: glow-xs 1.2s infinite alternate;
}

@keyframes glow-xs {
  from {
    box-shadow: 0 0 2px -2px #6b7280;
  }
  to {
    box-shadow: 0 0 2px 2px #6b7280;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 7px -7px #6b7280;
  }
  to {
    box-shadow: 0 0 7px 7px #6b7280;
  }
}

.ka-wrapper {
  width: 100%;
  height: 100%;
}

.ka-content {
  width: 100%;
  height: 100%;
}
#delivery-detail-popover .ant-popover-inner-content {
  padding-left: 0;
  padding-right: 0;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
  min-width: fit-content;

  li:hover {
    background-color: transparent !important;
  }
}

/* Intercom */

.intercom-with-namespace-52k34s {
  left: 20px !important;
  right: 0px !important;
}

.intercom-messenger-frame {
  left: 20px !important;
}

.intercom-lightweight-app-launcher {
  height: 1px !important;
  width: 1px !important;
  left: 20px !important;
  right: 0px !important;
}

.intercom-launcher-frame {
  height: 1px !important;
  width: 1px !important;
}

.intercom-launcher-frame .intercom-launcher-active {
  height: 48px !important;
  width: 48px !important;
}

.dispatch-map-tabs .ant-tabs-nav-list {
  width: 100%;
}

.dispatch-map-tabs .ant-tabs-nav-operations {
  display: none !important;
}

.dispatch-map-tabs .ant-tabs-tab {
  width: 45%;
  min-width: 100px;
  justify-content: center;
  padding: 8px !important;
}

.dispatch-map-tabs .ant-tab-tab .ant-tabs-tab-btn {
  text-align: center;
  font-size: 16px;
}

.ant-btn-compact-first-item.ant-btn-compact-last-item {
  border-radius: 3em !important;
}

.dropdown-button-shadow {
  button {
    background-color: #4100ff; /* Purple background */
    /* color: white; */
    /* padding: 10px 20px; */
    border: none;
    border-radius: 5px;
    /* font-size: 16px; */
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(65, 0, 255, 0.5), /* Outer shadow */ 0 1px 3px rgba(65, 0, 255, 0.4); /* Inner shadow */
    transition: box-shadow 0.3s ease;
  }
  button:hover {
    box-shadow: 0 6px 8px rgba(65, 0, 255, 0.5),
      /* Outer shadow on hover */ 0 2px 4px rgba(65, 0, 255, 0.4); /* Inner shadow on hover */
  }
}

.ant-input-outlined {
  border-color: #d9d9d9 !important;
}
.drivers-view-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  column-gap: 1.5rem;
}

.drivers-view-grid-container .team-group {
  display: block;
}

.drivers-view-grid-container .column-title {
  grid-column: span 1;
}

.drivers-view-grid-container .column-title.col-span-3 {
  grid-column: span 3;
}

.drivers-view-grid-container .column-title.col-span-2 {
  grid-column: span 2;
}

.team-group > div {
  width: 100%;
}

.team-group > * {
  width: 100%;
}
